import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      // {
      //   path: 'adekh-dashboard',
      //   loadChildren: () =>
      //     import('./adekh-dashboard/adekh-dashboard.module').then((m) => m.AdekhDashboardModule)
      // },
      {
        path: 'manage-content',
        loadChildren: () =>
          import('./manage-content/manage-content.module').then((m) => m.ManageContentModule)
      },
      {
        path: 'manage-subscription',
        loadChildren: () =>
          import('./manage-subscription/manage-subscription.module').then((m) => m.ManageSubscriptionModule)
      },
      // {
      //   path: 'manage-notifications',
      //   loadChildren: () =>
      //     import('./manage-notifications/manage-notifications.module').then((m)=>m.ManageNotificationsModule)
      // },
      // {
      //   path: 'manage-others',
      //   loadChildren: () =>
      //     import('./manage-others/manage-others.module').then((m)=>m.ManageOthersModule)
      // },
      // {
      //   path: 'extra-pages',
      //   loadChildren: () =>
      //     import('./extra-pages/extra-pages.module').then(
      //       (m) => m.ExtraPagesModule
      //     )
      // },
      // {
      //   path: 'multilevel',
      //   loadChildren: () =>
      //     import('./multilevel/multilevel.module').then(
      //       (m) => m.MultilevelModule
      //     )
      // }
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
