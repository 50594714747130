import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { RequestService } from './request.service';

var apiUrl = environment.apiUrl
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient, private requestservice: RequestService) { }
  getContentTypes() {
    return this.http.get(apiUrl.concat('contentType/'))
  }
  addContentType(value) {
    return this.http.post(apiUrl.concat('contentType/'), value)
  }
  getLibrary() {
    return this.http.get(apiUrl.concat('movieLibrary/'))
  }

  getMoviesData(){
    return this.http.get(`${environment.apiUrl}get_movies_data/`)
  }

  getContentTypeDetailsData(val:any){
    return this.http.get(`${environment.apiUrl}get_content_type_details/`,{params:val})
  }
  
  addLibrary(formData) {

    return this.http.post(`${environment.apiUrl}movieLibrary/`,formData)
        .pipe(map(data => {
          data['status'] = 1;
          return data;
        }),
        catchError(this.errorHandler)
    );
  }
  saveMobileLibraryImage(file) {
    return this.http.post<any>(`${apiUrl}library_mobile_image_upload/`, file);
  }
  saveWebLibraryImage(file) {
    return this.http.post<any>(`${apiUrl}library_web_image_upload/`, file);
  }
  saveWebHomeImage(file) {
    return this.http.post<any>(`${apiUrl}main_web_home_image_upload/`, file);
  }
  saveWebCoverImage(file) {
    return this.http.post<any>(`${apiUrl}main_web_cover_image_upload/`, file);
  }
  saveWebPotraitImage(file) {
    return this.http.post<any>(`${apiUrl}main_web_potrait_image_upload/`, file);
  }
  saveMobileHomeImage(file) {
    return this.http.post<any>(`${apiUrl}main_mobile_home_image_upload/`, file);
  }
  saveMobileCoverImage(file) {
    return this.http.post<any>(`${apiUrl}main_mobile_cover_image_upload/`, file);
  }
  saveMobilePotraitImage(file) {
    return this.http.post<any>(`${apiUrl}main_mobile_potrait_image_upload/`, file);
  }
  addMobileTrailerImage(file) {
    return this.http.post<any>(`${apiUrl}trailer_mobile_image_upload/`, file);
  }
  addWebTrailerImage(file) {
    return this.http.post<any>(`${apiUrl}trailer_web_image_upload/`, file);
  }
  addWebContentImage(file) {
    return this.http.post<any>(`${apiUrl}content_web_image_upload/`, file);
  }
  addMobileContentImage(file) {
    return this.http.post<any>(`${apiUrl}content_mobile_image_upload/`, file);
  }
  addHomeBannerMobileImage(file) {
    return this.http.post<any>(`${apiUrl}home_banner_mobile_image_upload/`, file);
  }
  addHomeBannerWebImage(file) {
    return this.http.post<any>(`${apiUrl}home_banner_web_image_upload/`, file);
  }
  bandMobileImage(file) {
    return this.http.post<any>(`${apiUrl}band_mobile_image_upload/`, file);
  }
  bandWebImage(file) {
    return this.http.post<any>(`${apiUrl}band_web_image_upload/`, file);
  }
  landingBackgroundImage(file) {
    return this.http.post<any>(`${apiUrl}landing_bakcground_image_upload/`, file);
  }
  getCastInfo(){
    return this.http.get(`${apiUrl}get_cast_details/`);
  }
  // saveInformation(form) {
  //   return this.http.post<any>(`${apiUrl}add_information/`, form);
  // }
  // updateInformation(form) {
  //   return this.http.patch<any>(`${apiUrl}add_information/`, form, { reportProgress: true, observe: 'events' });
  // }
  saveInformation(formData){
    
    if (formData.get('id')){

      return this.http.put(`${environment.apiUrl}addInformation/${formData.get('id')}/`,formData)
      .pipe(map(data =>{
        data['status'] = 2;
        return data
      }),
       catchError(this.errorHandler)
      );

    }
    else{

      return this.http.post(`${environment.apiUrl}addInformation/`,formData)
      .pipe(map(data => {
        data['status'] = 1;
        return data;
      }),
        catchError(this.errorHandler)
      );
    }

  }
  getInformation() {
    return this.http.get<any>(`${apiUrl}get_information/`);
  }

  uploadContent(contentData){
    return this.http.post(`${apiUrl}content_upload/`, contentData)
  }

  // getcrewmasterdata() {
  //   return this.http.get(apiUrl.concat('moviecrewmaster/'))

  // }
  // getcrewdetailsdata() {
  //   return this.http.get(apiUrl.concat('moviecrewdetails/'))

  // }
  addCrewSubDetails(form) {
    return this.http.post<any>(`${apiUrl}crewsubdetails/`, form);
  }
  getseasons(movie_ref_id) {
    return this.http.get(apiUrl.concat('season/?movie_ref_id=' + movie_ref_id))
  }
  addUpdateSeason(value) {
    if ("id" in value) {
      return this.http.patch(apiUrl.concat(`season/${value.id}/`), value)
    }
    else {
      return this.http.post(apiUrl.concat(`season/`), value)
    }
  }
  getTrailer(movie_ref_id) {
    return this.http.get(apiUrl.concat(`trailer/?movie_ref_id=${movie_ref_id}`))
  }
  getTrailerTypes() {
    return this.http.get(apiUrl.concat(`master/?master_type=Trailer Type`))
  }
  getContentVideoTypes() {
    return this.http.get(apiUrl.concat(`master/?master_type=Content Type`))
  }
  addUpdateTrailer(formData) {

    console.log(formData.get('id'),'afterrrrrrrrrrr');
    

    if (formData.get('id')!=null && formData.get('id')!='') {
      return this.http.patch(apiUrl.concat(`add_trailer/`), formData)
      .pipe(map(data=>{
        data['status'] = 1;
        return data;
      })
      );
    }
    else {
      return this.http.post(apiUrl.concat(`add_trailer/`), formData)
      .pipe(map(data=>{
        data['status'] = 1;
        return data;
      }),
      catchError(this.errorHandler)
      );
    }
  }
  getContent(movie_ref_id) {
    return this.http.get(apiUrl.concat(`get_content/?movie_ref_id=${movie_ref_id}`))
  }
  addUpdateContent(formData) {
    if (formData.get('id')!=null && formData.get('id')!='') {
      return this.http.patch(`${apiUrl}get_content/`, formData)
    }
    else {
      return this.http.post(`${apiUrl}get_content/`, formData)
    }
  }
  addUpdateConfig(value) {
    if (value.id!=null && value.id!='') {
      return this.http.patch(apiUrl.concat(`config/${value.id}/`), value)
    }
    else {
      return this.http.post(apiUrl.concat(`config/`), value)
    }
  }
  getTrailerContent(movie_ref_id, content) {
    return this.http.get(apiUrl.concat(`trailerandcontent/?movie_ref_id=${movie_ref_id}&content=${content}`))
  }
  deleteMovieLibraryData(id: any) {
    return this.http.delete(`${environment.apiUrl}movieLibrary/?id=${id}`)
  }
  getLibWiseData(data) {
    return this.http.get(apiUrl.concat(`libwiselibdetails/`),{params:data})
  }

  updateLibData(lib_ref_id, data) {
    return this.http.patch(apiUrl.concat(`libwiselibdetails/?library_ref_id_id=${lib_ref_id}`), data)
  }

  updateContentTypeLibrary(content_type_ref_id, data) {
    return this.http.patch(apiUrl.concat(`get_content_type_details/?content_type_ref_id=${content_type_ref_id}`), data)
  }

  updateHomeBannerSequence(content_type_ref_id,data) {
    return this.http.patch(apiUrl.concat(`getadminhomesliderdata/?content_type_ref_id=${content_type_ref_id}`), data)
  }

  saveLibraryData(formData) {

    if (formData.get('id')) {
      // return this.http.patch(apiUrl.concat('movieLibrary/' + formData.get('id') + '/'), formData)
      return this.http.put(`${environment.apiUrl}movieLibrary/${formData.get('id')}/`,formData)
        .pipe(map(data => {
          data['status'] = 2;
          return data;
        }),
          catchError(this.errorHandler)
        );
    }
    else {
      // return this.http.post(apiUrl.concat('movieLibrary/'), formData)
      return this.http.post(`${environment.apiUrl}movieLibrary/`,formData)
        .pipe(map(data => {
          data['status'] = 1;
          return data;
        }),
          catchError(this.errorHandler)
        );
    }
  }
  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server Error")
  }

  getHomeBannerData(val) {
    return this.http.get(apiUrl.concat(`getadminhomesliderdata/`),{params:val})
  }
  getActiveMovies() {
    return this.http.get(apiUrl.concat(`getactivemovies/`))
  }
  saveHomeBannerData(formData) {
    if (formData.get('id')!='' && formData.get('id')!=null) {
      return this.http.patch(apiUrl.concat('addhomeimageslider/'), formData)
        .pipe(map(data => {
          data['api_status'] = 2;
          return data;
        }),
          catchError(this.errorHandler)
        );
    }
    else {
      return this.http.post(apiUrl.concat('addhomeimageslider/'), formData)
        .pipe(map(data => {
          data['api_status'] = 1;
          return data;
        }),
          catchError(this.errorHandler)
        );
    }
  }
  getAllMasterData(master_type) {
    return this.http.get(apiUrl.concat("master/?master_type=" + master_type));
  }
  saveAdBandData(formData) {
    if (formData.get('id')!='' && formData.get('id')!=null){
      return this.http.patch(
        apiUrl.concat("save_adband/" + formData.get('id') + "/"),
        formData
      ).pipe(map(data => {
        data['api_status'] = 2;
        return data;
      }),
        catchError(this.errorHandler)
      );
    } else {
      return this.http.post(
        apiUrl.concat("save_adband/"),
        formData
      ).pipe(map(data => {
        data['api_status'] = 1;
        return data;
      }),
        catchError(this.errorHandler)
      );
    }
  }
  getAllAdBandData() {
    return this.http.get(apiUrl.concat("ad_band/"))
  }
  getUserTypes() {
    return this.http.get(apiUrl.concat("get_user_types/"))
  }
  saveSubscription(formData) {
    if (formData.id) {
      return this.http.patch(apiUrl.concat('save_subscriptionplan/'), formData)
        .pipe(map(data => {
          data['status'] = 2;
          return data;
        }),
          catchError(this.errorHandler)
        );
    }
    else {
      return this.http.post(apiUrl.concat('save_subscriptionplan/'), formData)
        .pipe(map(data => {
          data['status'] = 1;
          return data;
        }),
          catchError(this.errorHandler)
        );
    }
  }
  getCountryData() {
    return this.http.get(apiUrl.concat("country/"))
  }
  saveSubscriptionDetails(data,plan_ref_id) {
   return this.http.patch(apiUrl.concat(`save_subscriptionplandetails/?plan_ref_id=${plan_ref_id}`), data)
  }
  getSubscriptionPlnas()
  {
    return this.http.get(apiUrl.concat(`save_subscriptionplan/`))
  }
  getSubscriptionPlnasDetails(subscription_plan_mst_ref_id)
  {
    return this.http.get(apiUrl.concat(`save_subscriptionplandetails/?subscription_plan_mst_ref_id=${subscription_plan_mst_ref_id}`))
  }
  getlogindata(payload)
  {
    return this.http.post(apiUrl.concat(`login_user/`),payload)
  }
  deleteHomeBanner(id)
  {
    return this.http.delete(apiUrl.concat(`addhomeimageslider/?id=${id}`))
  }
  getDeviceTypes() {
    return this.http.get(apiUrl.concat(`master/?master_type=Device Type`))
  }
  savelanding(form)
  {
    return this.http.post(apiUrl.concat(`landingpage/`),form)
  }
  updatelanding(form)
  {
    return this.http.patch(apiUrl.concat(`landingpage/`),form)
  }
  getLanding()
  {
    return this.http.get(apiUrl.concat(`landingpage/`))
  }
  getUnSubscribedUsers()
  {
    return this.http.get(apiUrl.concat('unsubusers/?filter=all'))
  }
  getSubscribedUsers()
  {
    return this.http.get(apiUrl.concat('subusers/?filter=all'))
  }
  getSubscribedUsersCustomRange(from_date,to_date)
  {
    return this.http.get(apiUrl.concat('subusers/?filter=custom&from_date='+from_date+'&to_date='+to_date))
  }
  getUnSubscribedUsersCustomRange(from_date,to_date)
  {
    return this.http.get(apiUrl.concat('unsubusers/?filter=custom&from_date='+from_date+'&to_date='+to_date))
  }
  getcontentwatchtime(content_payload : any)
  {
    return this.http.get(`${environment.apiUrl}getcontentwatchtime/`,{params:content_payload})
  }
  contenthitsreports(content_payload:any)
  {
  return this.http.get(`${environment.apiUrl}getcontenthitsreports/`,{params:content_payload});
  }
  getNumberofViewsreports(content_payload:any)
  {
    return this.http.get(`${environment.apiUrl}getNumberofViewsreports/`,{params:content_payload});
  }

  getNotificationsData(){
    return this.http.get(`${environment.apiUrl}notification/`);
  }

  getNotificationTypes(){
    return this.http.get(`${environment.apiUrl}master/?master_type=Mobile Notification Type`);
  }

  saveNotification(formData){
    
    if (formData.get('id')){

      return this.http.put(`${environment.apiUrl}notification/${formData.get('id')}/`,formData)
      .pipe(map(data =>{
        data['status'] = 2;
        return data
      }),
       catchError(this.errorHandler)
      );

    }
    else{

      return this.http.post(`${environment.apiUrl}notification/`,formData)
      .pipe(map(data => {
        data['status'] = 1;
        return data;
      }),
        catchError(this.errorHandler)
      );
    }

  }


  saveContentTypeDetails(formData){
    
    if (formData.id){

      return this.http.put(`${environment.apiUrl}contentTypeDetails/${formData.id}/`,formData)
      .pipe(map(data =>{
        data['status'] = 2;
        return data
      }),
       catchError(this.errorHandler)
      );

    }
    else{

      return this.http.post(`${environment.apiUrl}contentTypeDetails/`,formData)
      .pipe(map(data => {
        data['status'] = 1;
        return data;
      }),
        catchError(this.errorHandler)
      );
    }

  }

  deleteContentTypeDetails(id)
  {
    return this.http.delete(apiUrl.concat(`contentTypeDetails/?id=${id}`))
  }

  deleteNotification(formData){

    return this.http.put(`${environment.apiUrl}notification/${formData.get('id')}/`,formData)
    .pipe(map(data=>{
      data['status'] = 1;
      return data;
    }),
    catchError(this.errorHandler)
    );

  }
  
  getcountrywiseuserscount(country_payload:any)
  {
    return this.http.get(`${environment.apiUrl}numberofUserscountrywise/`,{params:country_payload});
  }
  gettotalnumberofusers()
  {
    return this.http.get(apiUrl.concat('totalnumberofusers/'))
  }
  gettotalnumberofsubusers()
  {
    return this.http.get(apiUrl.concat('totalnumberofsubusers/'))
  }
  getactivenumberofsubusers()
  {
    return this.http.get(apiUrl.concat('totalnumberofactiveusers/'))
  }
  getpercentagebydevice()
  {
    return this.http.get(apiUrl.concat('checkdevicetypePercentage/'))
  }
  getusersbyplan()
  {
    return this.http.get(apiUrl.concat('planwiseuser/'))
  }
  countrywiseplanforpie()
  {
    return this.http.get(apiUrl.concat('userscountrywisecount/'))
  }
  getCountryType(){
    return this.http.get(apiUrl.concat('getcountryname/')) 
  }
  getScheduledNotifications(){
    return this.http.get(`${environment.apiUrl}scheduleNotifications/`)
  }
  saveScheduledNotification(formData){
    if(formData.id){
      return this.http.put(`${environment.apiUrl}scheduleNotifications/${formData.id}/`,formData)
      .pipe(map(data =>{
        data['status'] = 2;
        return data
      }),
      catchError(this.errorHandler)
      )
    }
    else{
      return this.http.post(`${environment.apiUrl}scheduleNotifications/`,formData)
      .pipe(map(data =>{
        data['status'] = 1;
        return data
      }),
      catchError(this.errorHandler)
      )
    }
  }
  getCustomNotificationScheduleData(payload:any)
  {
    return this.http.get(`${environment.apiUrl}getcustomnotificationscheduledata/`,{params:payload});
  }
}  