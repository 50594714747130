import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  // {
  //   path:'',
  //   title: 'Dasboard',
  //   moduleName: 'adekh-dashboard',
  //   icon: 'activity',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu:[
  //     {
  //       path: 'adekh-dashboard/overview',
  //       title: 'Overview',
  //       moduleName: 'adekh-dashboard',
  //       icon: 'layout',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //   ]
  // },
  {
    path:'',
    title: 'Manage  Reports',
    moduleName: 'dashboard',
    icon: 'bar-chart-2',
    class: 'menu-toggle',
    groupTitle: false,
    submenu:[
      {
        path: 'dashboard/main',
        title: 'User Reports',
        moduleName: 'dashboard',
        icon: 'layout',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
  //     {
  //       path: 'dashboard/content-reports',
  //       title: 'Content Reports',
  //       moduleName: 'dashboard',
  //       icon: 'layout',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
    ]
  },
  {
    path: '',
    title: 'Manage  Content',
    moduleName: 'manage-content',
    icon: 'film',
    class: 'menu-toggle',
    groupTitle: false,
    submenu:[
      {
        path: '/manage-content/upload-content',
        title: 'Upload Content',
        moduleName: 'manage-content',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/manage-content/movie-library',
        title: 'Content Library',
        moduleName: 'manage-content',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/manage-content/content-section',
        title: 'Content Section',
        moduleName: 'manage-content',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/manage-content/content-order-sequence',
        title: 'Content Order Sequence',
        moduleName: 'manage-content',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/manage-content/home-banner',
        title: 'Home Banner',
        moduleName: 'manage-content',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
      {
        path: '/manage-content/band-advertisement',
        title: 'Band Advertisement',
        moduleName: 'manage-content',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  {
    path:'',
    title: 'Manage  Subscription',
    moduleName: 'manage-subscription',
    icon: 'credit-card',
    class: 'menu-toggle',
    groupTitle: false,
    submenu:[
      {
        path: '/manage-subscription/manage-plans',
        title: 'Manage Plans',
        moduleName: 'manage-subscription',
        icon: 'layout',
        class: 'ml-menu',
        groupTitle: false,
        submenu: []
      },
    ]
  },
  // {
  //   path:'',
  //   title: 'Manage Notifications',
  //   moduleName: 'manage-notifications',
  //   icon: 'bell',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu:[
  //     {
  //       path: '/manage-notifications/create-notifications',
  //       title: 'Create Notifications',
  //       moduleName: 'manage-notifications',
  //       icon: 'layout',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //     {
  //       path: '/manage-notifications/schedule-notifications',
  //       title: 'Schedule Notifications',
  //       moduleName: 'manage-notifications',
  //       icon: 'layout',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     }
  //   ]
  // },
  // {
  //   path:'',
  //   title: 'Manage Others',
  //   moduleName: 'manage-others',
  //   icon: 'shopping-bag',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   submenu:[
  //     {
  //       path: '/manage-others/landing-page-configuration',
  //       title: 'Manage Landing Page',
  //       moduleName: 'manage-others',
  //       icon: 'layout',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       submenu: []
  //     },
  //   ]
  // },
];
