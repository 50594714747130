import { AuthService } from '../service/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthService) {}

  handleError(error: HttpErrorResponse){

    return throwError(()=> JSON.stringify(error));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): 
  Observable<HttpEvent<any>>{

    return next.handle(req)
    .pipe(
      catchError(this.handleError)
    )
  };
  // intercept(
  //   request: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   return next.handle(request).pipe(
  //     catchError((err:HttpErrorResponse ) => {
  //       let errorMessage = ''

  //       if (err.status === 401) {
  //         // auto logout if 401 response returned from api
  //         this.authenticationService.logout();
  //         location.reload();
  //       }
  //       else if (err.error instanceof ErrorEvent){
  //         errorMessage = `Error: ${err.error.message}`
  //       }
  //       else{
  //         errorMessage = `Error Code: ${err.status}\n ${err.message}`
  //       }

  //       // const error = err.error.message || err.statusText;
  //       // return throwError(error);
  //       window.alert(errorMessage)
  //       return throwError(()=>{err})
  //     })
  //   );
  // }
}
