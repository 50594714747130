import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import {} from './../../environments/environment.prod'
import { environment } from './../../environments/environment';

const baseUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  // requestoptions: any;

  constructor(
    private http: HttpClient
  ) { }


  /**
   * 
   * @param url the url
   * @param options the request options
   * @returns 
   */
  get(url: string, options?): Observable<any>{
    return this.http.get(`${baseUrl}${url}`,this.requestoptions(options)).pipe(
      catchError(err => this.catchAuthError(err)),
    );
  }

  post(url: string, body: any | null, options?): Observable<any>{
    return this.http.post(`${baseUrl}${url}`, body, this.requestoptions(options)).pipe(
      catchError(err => this.catchAuthError(err)),
    );
  }

  patch(url: string, body: any | null, options?): Observable<any>{
    return this.http.patch(`${baseUrl}${url}`, body, this.requestoptions(options)).pipe(
      catchError(err => this.catchAuthError(err)),
    );
  }

  put(url: string, body: any | null, options?): Observable<any>{
    return this.http.put(`${baseUrl}${url}`, body, this.requestoptions(options)).pipe(
      catchError(err => this.catchAuthError(err)),
    );
  }

  delete(url: string, options?): Observable<any>{
    return this.http.delete(`${baseUrl}${url}`, this.requestoptions(options)).pipe(
      catchError(err => this.catchAuthError(err)),
    );
  }

  private requestoptions(options){
    let tmpOptions = options;
    if(!tmpOptions){
      tmpOptions = {};
    }

    let headers;
    if(tmpOptions.headers){
      headers = tmpOptions.headers;
    }
    else{
      headers = new HttpHeaders();
    }

    if(!options || !options.isFile){
      headers = headers.append('Content-Type','application/json');
    }
    tmpOptions.headers = headers;
    return tmpOptions;
  }

  catchAuthError(error): Observable<Response>{
      if(error && error.error && error.error.message){
        return throwError(() => new Error(error))
      }
      else if(error && error.error.message){
        return throwError(() => new Error(error))
      }
      else{
        return throwError(() => new Error(error))
        // return error.message
      }
      
    // return throwError(() => new Error(JSON.stringify(error)));
  }


}
